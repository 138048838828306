import { Auth } from 'aws-amplify';

export const signIn = async (username, password, newPassword) => {
    try {
        const user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (!newPassword) {
                return ({ error: 'NEW_PASSWORD_REQUIRED' });
            }
            await Auth.completeNewPassword(
                user,               // the Cognito User Object
                newPassword,       // the new password
            );
        }
        return { user };
    } catch (err) {
        console.log('ERR:', err);
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
        }
        return { error: err.message ? err.message : "Error occured" };
    }
}


export const continueSession = async () => {
    let authenticated = false;
    let user;
    try {
        user = await Auth.currentAuthenticatedUser();
        authenticated = true;
    }
    catch (err) {
        console.log('ERR:', err);
    }
    return { authenticated, user };
}

export const getUserDetails = async () => {
    const user = await Auth.currentUserInfo();
    const { sub: userId, email, name: firstName, family_name: lastName, phone_number: contactNo, 'dev:custom:orgId': orgId, 'dev:custom:ouId': ouId } = user.attributes;
    return {
        userId, email, firstName, lastName, contactNo, ouId, orgId, username: user.username
    }
}

export const getUserGroups = async () => {
    const session = await Auth.currentSession();
    const groups = session.idToken.payload['cognito:groups'];
    return groups;
}

export const isSuperUser = async () => {
    let groups = await getUserGroups();
    return groups && groups.includes('Superusers');
}

export const isAdminUser = async () => {
    let groups = await getUserGroups();
    return groups && groups.includes('Adminusers');
}

export const isAnyAdminUser = async () => {
    let groups = await getUserGroups();
    return groups && (groups.includes('Adminusers') || groups.includes('Superusers'));
}

export const logout = async () => {
    try {
        await Auth.signOut();
    }
    catch (err) {
        console.log('ERR:', err);
    }
}

export const updateCurrentUserPassword = async (oldPassword, newPassword) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
    } catch ({ message }) {
        return message;
    }
}