import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { isAnyAdminUser, isSuperUser as checkSuperUser, logout } from "../../actions/AuthActions";
import PrintersView from "../printers/PrintersView";
import UsersView from "../users/UsersView";
import { Layout, Row, Col, Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { MENU, SideMenu } from './SideMenu';
import UserView, { USER_VIEW } from '../users/UserView';


const { Header, Content } = Layout;

const Dashboard = ({ setIsAuthenticated, user }) => {
    let history = useHistory();
    const [selectedMenu, setSelectedMenu] = useState(MENU.PRINTERS);
    const [isProfileView, setIsProfileView] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isCustomerView, setIsCustomerView] = useState(false);

    useEffect(() => {
        isAnyAdminUser().then(val => setIsAdmin(val));
        checkSuperUser().then(val => setIsSuperUser(val));
    }, []);

    const handleLogout = async () => {
        await logout();
        setIsAuthenticated(false);
        history.push("/login");
    };

    const userMenu = (
        <Menu className="user-menu-dropdown">
            <Menu.Item key="0" className="user-menu-dropdown-text" onClick={() => setIsProfileView(true)}>
                Edit Profile
            </Menu.Item>
            {isAdmin && <Menu.Item key="0" className="user-menu-dropdown-text" onClick={() => { setIsCustomerView(val => !val); setSelectedMenu(MENU.PRINTERS) }}>
                { isCustomerView ? 'Admin View' : 'Customer View' } 
            </Menu.Item>
            }
            <Menu.Item key="1" onClick={handleLogout} className="user-menu-dropdown-text">
                Logout
        </Menu.Item>
        </Menu>
    );

    const getView = () => {
        if (isProfileView) {
            return <UserView onCancel={() => setIsProfileView(false)} viewType={USER_VIEW.PROFILE} />
        }
        return (
            <Row>
                {isAdmin && !isCustomerView &&
                    <Col span={1}>
                        <SideMenu setSelectedMenu={setSelectedMenu} />
                    </Col>
                }
                <Col span={23}>
                    {selectedMenu === MENU.PRINTERS && <PrintersView isAdmin={isAdmin && !isCustomerView} isSuperUser={isSuperUser && !isCustomerView} />}
                    {selectedMenu === MENU.USERS && <UsersView isProfileView={isProfileView} setIsProfileView={setIsProfileView} />}
                </Col>
            </Row>
        )

    }

    return (
        <Layout style={{ background: '#ffffff' }}>
            <Header className='header-bg'>
                <Row>
                    <Col span={4} className='printer-console-heading'>
                        3D Printer Console
                    </Col>
                    <Col span={8} offset={12} className='logged-in-user'>
                        <Dropdown overlay={userMenu} trigger={['click']} placement="bottomRight">
                            <a className="user-menu-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                                Logged in as {user.username}&nbsp;
                                <CaretDownOutlined className="user-menu-dropdown-link-icon" />
                            </a>
                        </Dropdown>
                    </Col>
                </Row>
            </Header>
            <Content>
                {getView()}
            </Content>
        </Layout >
    )
}

export default Dashboard;