import { message } from "antd";
import { getApi, APIS, postApi } from "../utils/api-utils";
import { runFileSync } from "./PrinterProxyActions";

export const getPrinters = async () => {
    const { printers, error } = await getApi(APIS.PRINTER_API, '/printers');
    if (error) {
        message.error(error);
    }
    return printers;
}

export const openSShChannel = async () => {
    const { error } = await postApi(APIS.PRINTER_API, '/open-ssh');
    if (error) {
        message.error(error);
        return false;
    }
    return true;
}

export const closeSShChannel = async () => {
    const { error } = await postApi(APIS.PRINTER_API, '/close-ssh');
    if (error) {
        message.error(error);
        return false;
    }
    return true;
}

export const closeAllChannels = async () => {
    const { error } = await postApi(APIS.PRINTER_API, '/close-all');
    if (error) {
        message.error(error);
        return false;
    }
    return true;
}

export const getSshTunnel = async () => {
    const { sshTunnel, error } = await getApi(APIS.PRINTER_API, '/ssh-tunnel');
    if (error) {
        message.error(error);
    }
    return sshTunnel;
}

export const getOuScans = async () => {
    const { scanList } = await getApi(APIS.PRINTER_API, '/scans');
    return scanList;
}

export const getPendingUploads = async (printerId) => {
    const { fileList } = await getApi(APIS.PRINTER_API, `/pending-upload?printerId=${printerId}`);
    return fileList;
}

export const uploadOuFileToPrinter = async (printerId, fileKey, ouId) => {
    const { error } = await postApi(APIS.PRINTER_API, '/ou-file-to-printer', { printerId, fileKey });
    if (error) {
        return error;
    }else{
        await runFileSync(ouId);
    }
}

export const setPrinterNameInDB = async (printerId, name) => {
    const { error } = await postApi(APIS.PRINTER_API, '/printer-name', { printerId, name });
    if (error) {
        return error;
    }
}