import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './views/user/LoginPage';
import Dashboard from './views/dashboard/Dashboard';
import Loading from './components/Loading';
import UnProtectedRoute from './components/UnProtectedRoute';
import { continueSession } from './actions/AuthActions';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    continueSession().then(({ authenticated, user }) => {
      setIsAuthenticated(authenticated);
      setUser(user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <Switch>
        <UnProtectedRoute path="/login" isAuthenticated={isAuthenticated}>
          <LoginPage setIsAuthenticated={setIsAuthenticated} setUser={setUser} />
        </UnProtectedRoute>
        <ProtectedRoute path="/" isAuthenticated={isAuthenticated}>
          <Dashboard setIsAuthenticated={setIsAuthenticated} user={user} setUser={setUser} />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
}

export default App;
