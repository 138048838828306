import React from 'react';
import { Button, Col, Table, Row } from "antd";
import { useEffect, useState } from "react";
import { getUsers } from '../../actions/UserActions';
import UserView, { USER_VIEW } from './UserView';

const UsersView = () => {
    const [userList, setUserList] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [newUserViewVisible, setNewUserViewVisible] = useState(false);
    const [editUserViewVisible, setEditUserViewVisible] = useState(false);

    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Role', dataIndex: 'role', key: 'role', render: role => role === 'admin' ? 'Admin' : 'Customer' },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (key, user) => <a href="/#" onClick={() => { setSelectedUser(user); setEditUserViewVisible(true) }}>Edit</a>,
        },
    ];

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        getUsers().then(userList => setUserList(userList));
    }

    if (newUserViewVisible) {
        return <UserView onSave={() => { loadUsers(); setNewUserViewVisible(false) }} onCancel={() => setNewUserViewVisible(false)} viewType={USER_VIEW.NEW_USER} />
    }

    if (editUserViewVisible) {
        return <UserView onSave={() => { loadUsers(); setEditUserViewVisible(false) }} onCancel={() => { loadUsers(); setEditUserViewVisible(false); }} viewType={USER_VIEW.EDIT_USER} user={selectedUser} />
    }

    return (
        <div className='printers-view'>
            <Row style={{ marginTop: '20px', marginBottom: '50px' }}>
                <Col span={8} style={{ textAlign: 'left' }} className="page-title">
                    All Accounts
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                    <Button className="add-new-account-button" onClick={() => setNewUserViewVisible(true)}>
                        Add New Account
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Table
                    columns={columns}
                    dataSource={userList}
                    loading={userList === undefined}
                    style={{ width: '100%' }}
                />
            </Row>
        </div>
    )
}

export default UsersView;