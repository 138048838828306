import { Redirect, Route } from "react-router-dom";

const UnProtectedRoute = ({ isAuthenticated, children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={() =>

                !isAuthenticated ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/"
                            }}
                        />
                    )
            }
        />
    );
}

export default UnProtectedRoute;