import { message, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loading from "../../components/Loading";
import { getFileName } from "../../utils/common-utils";
import { getPrinterFileList, startPrint } from "../../actions/PrinterProxyActions";


export const StartPrintView = ({ isVisible, setIsVisible, printer, onPrint, ouId }) => {
    const [selectedFile, setSelectedFile] = useState();
    const [printing, setPrinting] = useState(false);
    const [printerFileList, setPrinterFileList] = useState([]);

    useEffect(() => {
        getPrinterFileList(printer.printerId, ouId).then(fileList => setPrinterFileList(fileList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStartPrint = async () => {
        Modal.confirm({
            title: `Do You Want to Print Following File?`,
            icon: <ExclamationCircleOutlined />,
            content: getFileName(selectedFile),
            onOk() {
                startPrintJob();
            }
        });
    };

    const startPrintJob = async () => {
        message.loading('Start printing...', 0);
        setPrinting(true);
        await startPrint(printer.printerId, printer.ouId, `0:/gcodes/${selectedFile}`);
        await onPrint();
        message.destroy();
        setIsVisible(false);
    }

    return (
        <Modal
            title="Start Print"
            visible={isVisible}
            onOk={() => onStartPrint()}
            onCancel={() => setIsVisible(false)}
            okText="Start Print"
            okButtonProps={{
                disabled: (selectedFile === undefined) || printing
            }}
        >
            {!printer && <Loading />}
            {
                printer &&
                <Row>
                    File to Print:
                    <Select onChange={(val, option) => setSelectedFile(val)} style={{ width: "70%" }} showSearch>
                        <Select.OptGroup label="Files On Printer">
                            {(printerFileList ? printerFileList : []).map(({ name }) => <Select.Option value={name}>{name}</Select.Option>)}
                        </Select.OptGroup>
                    </Select>
                </Row>
            }
        </Modal >
    );
}