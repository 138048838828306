import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Input, Select, message, Checkbox, Modal } from "antd";
import { addUser, deleteUser, updateUserEmail, updateUserRole, updateUserPassword, getUserDetailsFromDB, saveNotificationSettings, updateUserContactNo } from '../../actions/UserActions';
import { updateCurrentUserPassword } from '../../actions/AuthActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export const USER_VIEW = {
    NEW_USER: 'NEW_USER',
    PROFILE: 'PROFILE',
    EDIT_USER: 'EDIT_USER',
};

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const UserView = ({ onSave, onCancel, viewType, user }) => {
    const [form] = Form.useForm();
    const [askForCurrentPassword, setAskForCurrentPassword] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [smsNotifications, setSmsNotifications] = useState(false);

    useEffect(() => {
        if (viewType === USER_VIEW.PROFILE) {
            loadLoginUserDetails();
        } else if (viewType === USER_VIEW.EDIT_USER) {
            form.setFieldsValue({
                username: user.username,
                email: user.email,
                role: user.role,
                contactNo: user.contactNo,
            });
            setEmailNotifications(user.emailNotifications | false);
            setSmsNotifications(user.smsNotifications | false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLoginUserDetails = async () => {
        let { username, email, smsNotifications, emailNotifications, contactNo, role } = await getUserDetailsFromDB();
        setEmailNotifications(emailNotifications);
        setSmsNotifications(smsNotifications);
        setCurrentUser({ username, email, smsNotifications, emailNotifications, contactNo, role });
        form.setFieldsValue({
            username,
            email,
            role,
            contactNo
        });
    }

    const onFinish = async (values) => {
        switch (viewType) {
            case USER_VIEW.NEW_USER:
                await createUser(values);
                break;
            case USER_VIEW.PROFILE:
                await updateCurrentUser(values);
                break;
            case USER_VIEW.EDIT_USER:
                await updateUser(values);
                break;
            default:
                break;
        }
    };

    const createUser = async (user) => {
        message.loading('Creating user, please wait...', 0);
        let { userId, error } = await addUser(user);
        message.destroy();
        if (userId) {
            message.success('User created successfully.');
            onSave();
        } else if (error) {
            message.error(error === 'UsernameExistsException' ? 'Please choose a different username.' : error);
        }
    };

    const onDeleteUser = async () => {
        Modal.confirm({
            title: `Do You Want to Delete Following User?`,
            icon: <ExclamationCircleOutlined />,
            content: user.username,
            onOk: async () => {
                message.loading('Deleting user, please wait...', 0);
                let { error } = await deleteUser(user.username, user.userId);
                message.destroy();
                if (error) {
                    message.error(error);
                } else {
                    message.success('User deleted successfully.');
                    onSave();
                }
            }
        });
    };

    const updateCurrentUser = async ({ email, currentPassword, password, contactNo }) => {
        if (contactNo !== currentUser.contactNo) {
            message.loading('Updating contact number, please wait...', 0);
            let { error } = await updateUserContactNo(contactNo);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Contact number updated successfully.');
            }
        }
        if (email !== currentUser.email) {
            message.loading('Updating email, please wait...', 0);
            let { error } = await updateUserEmail(undefined, email);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Email updated successfully.');
            }
        }
        if (password) {
            message.loading('Updating password, please wait...', 0);
            let error = await updateCurrentUserPassword(currentPassword, password);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Pasword updated successfully.');
            }
        }
    };

    const onSaveSettings = async () => {
        message.loading('Saving settings, please wait...', 0);
        let error = await saveNotificationSettings(smsNotifications, emailNotifications, viewType === USER_VIEW.EDIT_USER ? user.userId : undefined);
        message.destroy();
        if (error) {
            message.error(error);
        } else {
            message.success('Settings saved successfully.');
        }
    }

    const updateUser = async ({ email, role, password, contactNo }) => {
        if (email !== user.email) {
            message.loading('Updating email, please wait...', 0);
            let { error } = await updateUserEmail(user.username, email, user.userId);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Email updated successfully.');
            }
        }
        if (role !== user.role) {
            message.loading('Changing user role, please wait...', 0);
            let { error } = await updateUserRole(user.username, role, user.userId);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('User updated successfully.');
            }
        }
        if (password && password.length) {
            message.loading('Updating password, please wait...', 0);
            let { error } = await updateUserPassword(user.username, password);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Password updated successfully.');
            }
        }
        if (contactNo !== user.contactNo) {
            message.loading('Updating contact number, please wait...', 0);
            let { error } = await updateUserContactNo(contactNo, user.userId);
            message.destroy();
            if (error) {
                message.error(error);
            } else {
                message.success('Contact number updated successfully.');
            }
        }
    };

    return (
        <div className='user-view'>
            <Row style={{ marginTop: '20px' }}>
                <Col span={8} style={{ textAlign: 'left' }} className="page-title">
                    {viewType === USER_VIEW.NEW_USER ? 'Create New Account' : viewType === USER_VIEW.PROFILE ? 'Your Account Details' : 'Edit User Account'}
                </Col>
                {viewType === USER_VIEW.EDIT_USER &&
                    <Col span={4} offset={12} style={{ textAlign: 'right' }}>
                        <Button className="small-button" onClick={async () => await onDeleteUser()}>Delete User</Button>
                    </Col>
                }
            </Row>
            <Row style={{ marginTop: '70px' }}>
                <Form form={form} onFinish={onFinish} className="new-user-form" {...layout}>
                    <Form.Item name="username" label="Username" rules={[{ required: true }]}>
                        <Input disabled={viewType !== USER_VIEW.NEW_USER} />
                    </Form.Item>
                    <Form.Item name="password" label="Password"
                        rules={[
                            { required: viewType === USER_VIEW.NEW_USER },
                            { min: 7, message: 'Password should have length greater than or equal to 7' }
                        ]}
                        extra={viewType !== USER_VIEW.NEW_USER ? 'Leave password blank if you are not changing it.' : undefined}>
                        <Input onChange={e => setAskForCurrentPassword(viewType === USER_VIEW.PROFILE && e.target.value && e.target.value.length)} />
                    </Form.Item>
                    {askForCurrentPassword &&
                        <Form.Item name="currentPassword" label="Current Password"
                            rules={[{ required: true }, { min: 7, message: 'Password should have length greater than or equal to 7' }]} >
                            <Input />
                        </Form.Item>
                    }
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="contactNo" label="Phone No" extra="Format +61234567890"
                        rules={[{ pattern: new RegExp("^\\+\\d{11}$"), message: 'Please enter with correct format (ex:+61234567890)' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="role" label="Role" rules={[{ required: true }]} >
                        <Select disabled={viewType === USER_VIEW.PROFILE}>
                            <Option value="admin">Admin</Option>
                            <Option value="customer">Customer</Option>
                        </Select>
                    </Form.Item>
                    <Button htmlType="button" className="cancel-button button" onClick={onCancel}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit" className="submit-button button">
                        {viewType === USER_VIEW.NEW_USER ? 'Create Account' : 'Save Changes'}
                    </Button>
                </Form>
            </Row>
            {viewType !== USER_VIEW.NEW_USER &&
                <div className="notification-settings">
                    <Row>
                        <Col span={8} style={{ textAlign: 'left', marginBottom: '73px' }} className="page-title">
                            Notification Settings
                </Col>
                    </Row>
                    <Form.Item name="notification" label="Notification">
                        <Row><Checkbox name="emailNotification" className="checkbox-text" checked={emailNotifications} onChange={e => setEmailNotifications(e.target.checked)}>Email</Checkbox></Row>
                        <Row><Checkbox name="smsNotification" className="checkbox-text" checked={smsNotifications} onChange={e => setSmsNotifications(e.target.checked)}>SMS</Checkbox></Row>
                    </Form.Item>
                    <Button htmlType="button" className="cancel-button button" onClick={onCancel}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit" className="submit-button button" onClick={onSaveSettings}>
                        Save Settings
                    </Button>
                </div>
            }
        </div>
    )
}

export default UserView;