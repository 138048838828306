import { Modal, Form, Input, Typography, message } from "antd";
import { setPrinterNameInDB } from "../../actions/PrinterActions";
import { setPrinterName } from "../../actions/PrinterProxyActions";

export const PrinterNamePopup = ({ isVisible, onOk, onCancel, printer }) => {
    const [form] = Form.useForm();

    const onSave = async () => {
        try {
            await form.validateFields();
            message.loading('Changing printer name');
            let printerName = form.getFieldValue('printerName');
            let error = await setPrinterName(printer.printerId, printer.ouId, printerName);
            if(!error){
                await setPrinterNameInDB(printer.printerId, printerName);
            }
            message.destroy();
            if(error){
                message.error(error);
            }else{
                message.success('Printer name updated successfully');
            }
            onOk();
        } catch (err) {

        }
    }

    return (
        <Form form={form} className="new-user-form">
            <Modal
                title={<Typography.Title level={4}>Change Printer Name</Typography.Title>}
                visible={isVisible}
                onOk={onSave}
                onCancel={onCancel}
            >
                <Form.Item
                    label="Name"
                    name="printerName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input printer name',
                        },
                    ]}
                    initialValue={printer.name}
                >
                    <Input />
                </Form.Item>
            </Modal>
        </Form>
    );
}