import { API, Auth } from 'aws-amplify';

export const APIS = {
  PRINTER_API: 'printerAPI',
  PRINTER_PROXY: 'printerProxy',
  PRINTER_PROXY_API: 'printerProxyAPI',
  USER_API: 'userAPI',
}

export async function getApi(api, path, params) {
  let options = (params) ? params : {};
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.get(api, path, options);
}

export async function putApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.put(api, path, options);
}

export async function postApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  try {
    const result = await API.post(api, path, options);
    return result;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      console.error('ERROR:', err.response.data.error);
      throw err.response.data.error;
    } else {
      console.error('ERROR:', err);
      throw err;
    }
  }
}

export async function delApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.del(api, path, options);
}

export async function uploadFile(url, file, contentType = 'application/pdf') {
  await fetch(url, {
    method: 'PUT',
    headers: {
      "Content-Type": contentType
    },
    body: file
  }).then(
    response => response.json() // if the response is a JSON object
  ).then(
    success => console.log(success) // Handle the success response object
  ).catch(
    error => console.log(error) // Handle the error response object
  );
}
