import { getApi, APIS, postApi } from "../utils/api-utils";
import moment from "moment";

export const getPrinterUpdate = async (printer, ouId, type = 1) => {
    try {
        let { name, status, timesLeft, output } = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printer.printerId}/rr_status?type=${type}`);
        if (name) printer.name = name;
        printer.status = status;
        printer.timesLeft = timesLeft;
        printer.lastUpdatedAt = moment();
        printer.output = output
    } catch (e) {
        printer.status = undefined;
    }
    return printer;
}

export const getPrinterFileList = async (printerId, ouId) => {
    try {
        let { files } = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_filelist?dir=0%3A%2Fgcodes&first=0`);
        return files;
    } catch (e) {
    }
}

export const getPrintingFile = async (printerId, ouId) => {
    try {
        let { fileName } = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_fileinfo`);
        return fileName;
    } catch (e) {

    }
}

export const startPrint = async (printerId, ouId, fileName) => {
    let path = encodeURIComponent(` "${fileName}"`);
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M32${path}`);
        console.log({ response });
    } catch (e) {

    }
}

export const stopPrint = async (printerId, ouId) => {
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M112%0AM999`);
        console.log({ response });
    } catch (e) {

    }
}

export const pausePrint = async (printerId, ouId) => {
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M25`);
        console.log({ response });
    } catch (e) {

    }
}

export const resumePrinting = async (printerId, ouId) => {
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M24`);
        console.log({ response });
    } catch (e) {

    }
}

export const deleteFile = async (printerId, ouId, fileName) => {
    let path = encodeURIComponent(` "${fileName}"`);
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M30${path}`);
        console.log({ response });
    } catch (e) {

    }
}

export const runMacro = async (printerId, ouId, fileName) => {
    let path = encodeURIComponent(` P"${fileName}"`);
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M98${path}`);
        console.log({ response });
    } catch (e) {

    }
}

export const getPrinterSnapshot = async (printerId) => {
    try {
        let { image } = await getApi(APIS.PRINTER_PROXY_API, `/camera-snapshot?printerId=${printerId}`);
        return image;
    } catch (e) {

    }
}

export const runFileSync = async (ouId) => {
    try {
        await postApi(APIS.PRINTER_PROXY, `/${ouId}/server/syncS3`);
    } catch (e) {

    }
}

export const setPrinterName = async (printerId, ouId, printerName) => {
    let path = encodeURIComponent(` P"${printerName}"`);
    try {
        let response = await getApi(APIS.PRINTER_PROXY, `/${ouId}/printer/${printerId}/rr_gcode?gcode=M550${path}`);
        console.log({ response });
    } catch (e) {
        return 'Update failed';
    }
}