import React, { useState } from 'react';
import { Form, Input, Button, message, Row, Card, Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { signIn } from '../../actions/AuthActions';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};
const LoginPage = ({ setIsAuthenticated, setUser }) => {
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [form] = Form.useForm();

    let location = useLocation();
    let history = useHistory();
    let { from } = location.state || { from: { pathname: "/" } };

    const onFinish = async (values) => {
        if (newPasswordRequired && values.newPassword !== values.newPassword2) {
            message.warning('New passwords does not match.');
            return;
        }
        message.loading('Please wait...', 0);
        const { error, user } = await signIn(values.username, values.password, values.newPassword);
        message.destroy();
        if (error) {
            if (error === 'NEW_PASSWORD_REQUIRED') {
                setNewPasswordRequired(true);
                message.info('Please set new password.');
                return;
            }
            message.error(error);
        } else {
            await setUser(user);
            await setIsAuthenticated(true);
            history.replace(from);
        }
    };

    return (
        <Row align="middle" justify="space-around" >
            <Card align="middle" justify="space-around" style={{ width: '600px', marginTop: '100px' }}>
                <Image
                    width={200}
                    src="/logo.png"
                />
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {newPasswordRequired &&
                        <React.Fragment>
                            <Form.Item
                                label="New Password"
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your new password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>


                            <Form.Item
                                label="Confirm New Password"
                                name="newPassword2"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please re-enter your new password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </React.Fragment>
                    }
                        <Button htmlType="submit">
                            LOGIN
                        </Button>
                    {/* <Form.Item>
                        <Link to="/forgot_password">I can't remember my password</Link>
                    </Form.Item> */}
                </Form>
            </Card>
        </Row>
    );
};

export default LoginPage;