import { message } from "antd";
import { getApi, APIS, postApi, delApi, putApi } from "../utils/api-utils";

export const getUsers = async () => {
    const { userList, error } = await getApi(APIS.USER_API, '/users');
    if (error) {
        message.error(error);
    }
    return userList;
}

export const addUser = async (user) => {
    const { userId, error } = await postApi(APIS.USER_API, '/user', user);
    return { userId, error };
}

export const deleteUser = async (username, userId) => {
    const { error } = await delApi(APIS.USER_API, '/user', { username, userId });
    return { error };
}

export const updateUserEmail = async (username, email, userId) => {
    const { error } = await putApi(APIS.USER_API, '/user-email', { username, email, userId });
    return { error };
}

export const updateUserContactNo = async (contactNo, userId) => {
    const { error } = await putApi(APIS.USER_API, '/user-contact-no', { contactNo, userId });
    return { error };
}

export const updateUserRole = async (username, role, userId) => {
    const { error } = await putApi(APIS.USER_API, '/user-role', { username, role, userId });
    return { error };
}

export const updateUserPassword = async (username, password) => {
    const { error } = await putApi(APIS.USER_API, '/user-password', { username, password });
    return { error };
}

export const getUserDetailsFromDB = async () => {
    const { userDetails, error } = await getApi(APIS.USER_API, '/user-details');
    if (error) {
        message.error(error);
    }
    return userDetails;
}

export const saveNotificationSettings = async (smsNotifications, emailNotifications, userId) => {
    const { error } = await putApi(APIS.USER_API, '/user-notifications', { smsNotifications, emailNotifications, userId });
    return error;
}