import React from 'react';
import { Button, Col, Collapse, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import {
    getPrinters, openSShChannel,
    closeSShChannel, closeAllChannels, getSshTunnel, getOuScans,
} from "../../actions/PrinterActions";
import { sleep } from "../../utils/common-utils";
import { ReloadOutlined, CopyOutlined } from '@ant-design/icons';
import PrinterCard from './PrinterCard';

const { Text } = Typography;

const PrintersView = ({ isAdmin, isSuperUser }) => {
    const [printers, setprinters] = useState([]);
    const [scans, setScans] = useState([]);
    const [sshTunnel, setSshTunnel] = useState(false);

    useEffect(() => {
        loadPrinters();
        loadScanList();
    }, []);

    useEffect(() => {
        if (isSuperUser) {
            loadSshTunnelData();
        }
    }, [isSuperUser]);

    const loadPrinters = async () => {
        const printers = await getPrinters();
        await getOuScans();
        setprinters(printers);
    };

    const loadScanList = async () => {
        let scans = await getOuScans();
        setScans(scans);
    };

    const loadSshTunnelData = async () => {
        const sshTunnel = await getSshTunnel();
        if (sshTunnel.url) {
            const [, url, port] = sshTunnel.url.replace('//', '').split(':');
            sshTunnel.command = `ssh -p ${port} odroid@${url}`;
        }
        setSshTunnel(sshTunnel);
    };

    const openSSh = async () => {
        message.loading('Openning ssh channel...', 0);
        await openSShChannel();
        await sleep(10000);
        await loadSshTunnelData();
        message.destroy();
    };

    const closeSSh = async () => {
        message.loading('Closing ssh channel...', 0);
        await closeSShChannel();
        await sleep(10000);
        await loadSshTunnelData();
        message.destroy();
    };

    const closeAll = async () => {
        message.loading('Closing all channels...', 0);
        await closeAllChannels();
        await sleep(10000);
        await loadPrinters();
        await loadSshTunnelData();
        message.destroy();
    };

    return (
        <div className='printers-view'>
            <Row style={{ marginTop: '20px' }}>
                <Col span={8} offset={16} style={{ textAlign: 'right' }}>
                    <Button onClick={() => window.location.reload()} icon={<ReloadOutlined />} ghost className='refresh-button'>
                        Refresh
                    </Button>
                </Col>
            </Row>
            {isSuperUser &&
                <Collapse style={{ marginTop: "10px" }}>
                    <Collapse.Panel header="SSH Access" key="1">
                        {sshTunnel && sshTunnel.url && <React.Fragment>SSH Command: <Text code copyable={{ icon: <CopyOutlined key="copy-icon" /> }}>{sshTunnel.command}</Text><Text disabled>(Opened At: {moment(sshTunnel.lastUpdatedAt).format('YYYY/MM/DD h:mm A')})</Text><br /></React.Fragment>}
                        <Button onClick={openSSh} ghost style={{ color: '#A26D6F', borderColor: '#A26D6F', marginTop: '10px' }}>Open SSH Channel</Button>
                        <Button onClick={closeSSh} ghost style={{ color: '#A26D6F', borderColor: '#A26D6F', marginLeft: '10px' }}>Close SSH Channel</Button>
                        <Button onClick={closeAll} ghost style={{ color: '#A26D6F', borderColor: '#A26D6F', marginLeft: '10px' }}>Close All Channels</Button>
                    </Collapse.Panel>
                </Collapse>
            }
            <Row gutter={[48, 48]} style={{ marginTop: '20px' }}>
                {printers.map(printer => <PrinterCard
                    key={printer.printerId}
                    printerId={printer.printerId}
                    adminUser={isAdmin}
                    scans={scans}
                    ouId={printer.ouId}
                    printerDataFromDB={printer}
                />)}
            </Row>
        </div>
    )
}

export default PrintersView;