import React from 'react';
import { Button, Card, Col, message, Modal, Tag, Tooltip, Typography, Popconfirm, Select, Row, Spin, Alert } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { getPrinterUpdate, getPrinterSnapshot, stopPrint, pausePrint, resumePrinting, runMacro } from "../../actions/PrinterProxyActions";
import { getFileName } from "../../utils/common-utils";
import {
    PRINTER_ERROR_STATUS, PRINTER_PROCESSING_STATUS, PRINTER_STATUS, PRINTER_STATUS_PAUSED, PRINTER_STATUS_PRINTING,
    PRINTER_STATUS_READY, PRINTER_SUCCESS_STATUS, PRINTER_WARNING_STATUS
} from './constants';
import { ExclamationCircleOutlined, SyncOutlined, EditOutlined } from '@ant-design/icons';
import { StartPrintView } from './StartPrintView';
import JSMpeg from '@cycjimmy/jsmpeg-player';
import { CONFIG } from '../../config/app-config';
import { FileManager } from './FileManager';
import { PrinterNamePopup } from './PrinterNamePopup';

const { Meta } = Card;
const { Title, Text } = Typography;
const { Option } = Select;
let printerStatusCronId, cameraSnapshotCronId, videoElement, videoTimeout;
// let printerStatusFailedCount = 0, cameraSnapshotFailedCount = 0;
// let MAX_RETRY_COUNT = 5;

const FIVE_MINUTES = 300000;
const THIRTY_SECS = 30000;

const CAMERA_VIEW = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO'
}

const DATE_TIME_FORMAT = 'YYYY/MM/DD h:mm A';
const DATE_TIME_FORMAT_WITH_S = 'YYYY/MM/DD h:mm:ss A';
const TIME_FORMAT = 'HH[h] mm[m] ss[s]';

const PrinterCard = ({ adminUser, ouId, printerDataFromDB, scans }) => {
    let printerId = printerDataFromDB.printerId;

    const [printer, setPrinter] = useState(printerDataFromDB);
    const [startPrintVisible, setStartPrintVisible] = useState(false);
    const [nameEditVisible, setNameEditVisible] = useState(false);
    const [fileManagerVisible, setFileManagerVisible] = useState(false);
    const [cameraSnapshot, setCameraSnapshot] = useState();
    const [cameraView, setCameraView] = useState(CAMERA_VIEW.IMAGE);

    useEffect(() => {
        loadPrinterData(2);
        loadCameraData();
        printerStatusCronId = setInterval(loadPrinterData, THIRTY_SECS);
        cameraSnapshotCronId = setInterval(loadCameraData, THIRTY_SECS);

        return () => {
            clearInterval(printerStatusCronId);
            clearInterval(cameraSnapshotCronId);
            if (videoElement?.player?.isPlaying) {
                videoElement.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cameraView === CAMERA_VIEW.VIDEO && document.getElementById('videoCanvas')) {
            let url = `${CONFIG.videoFeedBaseUrl}/${ouId}/video/${printerId}`
            videoElement = new JSMpeg.VideoElement('#videoCanvas', url);
        } else if (cameraView !== CAMERA_VIEW.VIDEO && videoElement?.player?.isPlaying) {
            videoElement.destroy();
        }
        if (cameraView === CAMERA_VIEW.VIDEO) {
            if (videoTimeout) clearTimeout(videoTimeout);
            videoTimeout = setTimeout(() => setCameraView(CAMERA_VIEW.IMAGE), FIVE_MINUTES);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraView]);

    const loadPrinterData = async (type = 3) => {
        let printerData = await getPrinterUpdate(printer, ouId, type);
        setPrinter({ ...printerData });
        // if (!printerData.status) {
        //     printerStatusFailedCount++;
        //     if (printerStatusFailedCount > MAX_RETRY_COUNT) {
        //         clearInterval(printerStatusCronId);
        //         console.log('MAX RETRIES P');
        //     }
        // } else {
        //     printerStatusFailedCount = 0;
        // }

    };

    const loadCameraData = async () => {
        if (cameraView === CAMERA_VIEW.IMAGE) {
            let image = await getPrinterSnapshot(printerId);
            if (image) {
                setCameraSnapshot({ image, time: moment() });
                // cameraSnapshotFailedCount = 0
            }
            // else {
            //     cameraSnapshotFailedCount++;
            //     if (cameraSnapshotFailedCount > MAX_RETRY_COUNT) {
            //         clearInterval(cameraSnapshotCronId);
            //         console.log('MAX RETRIES C');
            //     }
            // }
        }
    };

    const getPrinterStatus = (status) => {
        const { displayName, tooltip } = PRINTER_STATUS[status] ? PRINTER_STATUS[status] : { displayName: 'Connecting' }
        let tagType, icon;
        if (PRINTER_SUCCESS_STATUS.includes(status)) {
            tagType = 'success'
        } else if (PRINTER_PROCESSING_STATUS.includes(status)) {
            tagType = 'processing'
        } else if (PRINTER_WARNING_STATUS.includes(status)) {
            tagType = 'warning'
        } else if (PRINTER_ERROR_STATUS.includes(status)) {
            tagType = 'error'
        } else if (status === undefined) {
            tagType = 'processing'
            icon = <SyncOutlined spin />
        } else {
            tagType = 'error'
        }
        return <Tooltip title={tooltip}><Tag color={tagType} icon={icon}>{displayName}</Tag></Tooltip>
    }

    const onStopPrinting = async printerId => {
        Modal.confirm({
            title: `Are you sure you want to stop printing?`,
            icon: <ExclamationCircleOutlined />,
            content: `You cannot resume the print task if you stop the printer now`,
            okText: `Yes, stop printing`,
            okButtonProps: { 'danger': true },
            onOk: async () => {
                message.loading('Stopping printing...', 0);
                await stopPrint(printerId, ouId);
                message.destroy();
            }
        });
    };

    const onPausePrinting = async (printerId) => {
        message.loading('Pausing printing...', 0);
        await pausePrint(printerId, ouId);
        message.destroy();
    };

    const onResumePrinting = async (printerId) => {
        message.loading('Resuming printing...', 0);
        await resumePrinting(printerId, ouId);
        message.destroy();
    };

    const onLoadFilament = async (printerId) => {
        message.loading('Loading filament...', 0);
        await runMacro(printerId, ouId, '0:/macros/Filament Load Printer');
        message.destroy();
    }

    const onUnloadFilament = async (printerId) => {
        message.loading('Unloading filament...', 0);
        await runMacro(printerId, ouId, '0:/macros/Filament Unload Printer');
        message.destroy();
    }

    const getPrinterActions = () => {
        return (
            <React.Fragment>
                {printer.status === PRINTER_STATUS_READY &&
                    <>
                        <Button onClick={() => setStartPrintVisible(true)} type="primary" size='large' style={{ background: '#0e8219', textAlign: 'center', width: '150px' }}>
                            START
                        </Button><br />
                    </>
                }
                {printer.status === PRINTER_STATUS_PRINTING &&
                    <>
                        <Button onClick={() => onPausePrinting(printerId)} type="primary" size='large' danger style={{ width: '150px' }}>
                            PAUSE
                        </Button><br />
                    </>
                }
                {printer.status === PRINTER_STATUS_PAUSED &&
                    <>
                        <Button onClick={() => onResumePrinting(printerId)} type="primary" size='large' style={{ background: '#0e8219', width: '150px' }}>
                            RESUME
                        </Button><br />
                    </>
                }
                {(printer.status === PRINTER_STATUS_PRINTING || printer.status === PRINTER_STATUS_PAUSED) &&
                    <>
                        <Button onClick={() => onStopPrinting(printerId)} size='large' danger style={{ width: '150px', marginTop: '10px' }}>
                            STOP
                        </Button><br />
                    </>
                }
                {printer.status &&
                    <>
                        <Popconfirm title='Do You Want To Load Filament?' onConfirm={() => onLoadFilament(printerId)} okText="Yes" cancelText="No">
                            <Button type="primary" size='large' style={{ width: '150px', marginTop: '10px' }} ghost>
                                Load Filament
                            </Button>
                        </Popconfirm>
                        <br />
                        <Popconfirm title='Do You Want To Unload Filament?' onConfirm={() => onUnloadFilament(printerId)} okText="Yes" cancelText="No" >
                            <Button type="primary" size='large' style={{ width: '150px', marginTop: '10px' }} ghost>
                                Unload Filament
                            </Button>
                        </Popconfirm>
                        <br />
                    </>
                }
                <Button type="primary" size='large' style={{ width: '150px', marginTop: '10px' }} ghost onClick={() => setFileManagerVisible(true)}>
                    File Manager
                </Button>
            </React.Fragment>
        )
    }

    return (
        <Col span={12}>
            {startPrintVisible && <StartPrintView isVisible={startPrintVisible} setIsVisible={setStartPrintVisible} printer={printer} onPrint={loadPrinterData} scans={scans} ouId={ouId} />}
            {fileManagerVisible && <FileManager isVisible={fileManagerVisible} setIsVisible={setFileManagerVisible} printer={printer} scans={scans} ouId={ouId} />}
            {nameEditVisible && <PrinterNamePopup isVisible={nameEditVisible} onOk={() => { setNameEditVisible(false); loadPrinterData(); }} onCancel={() => setNameEditVisible(false)} printer={printer} />}
            <Card
                title={
                    <Meta
                        title={<Row><Title level={4}>{printer.name}</Title> <Button onClick={() => setNameEditVisible(true)} type="link" icon={<Tooltip title="Change Printer Name"><EditOutlined /></Tooltip>} /></Row>}
                        description={
                            <React.Fragment>
                                Status: {getPrinterStatus(printer.status)}<br />
                                {printer.fileName && printer.fileName.fileName && <React.Fragment>File: {getFileName(printer.fileName)}<br /></React.Fragment>}
                                Time Remaining: {printer.timesLeft ? moment.utc(printer.timesLeft.file * 1000).format(TIME_FORMAT) : ''}<br />
                                Filament Remaining:{printer.timesLeft ? moment.utc(printer.timesLeft.filament * 1000).format(TIME_FORMAT) : ''}<br />
                                {/* {adminUser && printer.status && <React.Fragment><a href={encodeURI(`${CONFIG.printerProxyUrl}/${ouId}/printer/${printerId}`)} target="_blank" rel="noreferrer">Open Duet3D Console</a><br /></React.Fragment>} */}
                                {adminUser && printer.status && <React.Fragment><a href={encodeURI(`${CONFIG.duetBaseUrl}?ouId=${ouId}&printerId=${printerId}`)} target="_blank" rel="noreferrer">Open Duet3D Console</a><br /></React.Fragment>}
                                <Text disabled>Last Updated At: {printer.lastUpdatedAt ? moment(printer.lastUpdatedAt).format(DATE_TIME_FORMAT) : ''}</Text>
                                {printer?.output?.msgBox && <Alert
                                    style={{ width: "80%" }}
                                    message={printer.output.msgBox.title}
                                    description={printer.output.msgBox.msg}
                                    type="info"
                                />
                                }
                            </React.Fragment>
                        }
                    />
                }
                extra={
                    <React.Fragment>
                        {getPrinterActions(printer.status, printerId, printer)}
                    </React.Fragment>
                }
                hoverable
                className='printer-card'
            >
                <Row>
                    <Col span={5} offset={19} style={{ marginBottom: '10px', textAlign: 'right' }}>
                        <Select value={cameraView} onChange={val => setCameraView(val)}>
                            <Option value={CAMERA_VIEW.IMAGE}>Image (30s)</Option>
                            <Option value={CAMERA_VIEW.VIDEO}>Live Video</Option>
                        </Select>
                    </Col>
                </Row>
                <Row>
                    {cameraView === CAMERA_VIEW.IMAGE && <>
                        <Spin tip="Loading Camera Snapshot..." spinning={cameraSnapshot === undefined}>
                            <img alt='' style={{ minHeight: '320px', minWidth: '570px', width: '100%' }} src={cameraSnapshot?.image} /><br />
                            {cameraSnapshot !== undefined && <div style={{ textAlign: 'right' }}>{`Captured at: ${moment(cameraSnapshot?.time).format(DATE_TIME_FORMAT_WITH_S)}`}</div>}
                        </Spin>
                    </>}
                    {cameraView === CAMERA_VIEW.VIDEO && <div id="videoCanvas" style={{ width: '100%', paddingTop: '56%' }}></div>}
                </Row>
            </Card>
        </Col>
    )
}

export default PrinterCard;