export const CONFIG = {
  baseApiUrl: 'https://b5wxzds8fl.execute-api.us-west-2.amazonaws.com/dev',
  printerProxyApiUrl: 'https://71nbdiml2c.execute-api.us-west-2.amazonaws.com/dev',
  userApiUrl: 'https://o9g76iug85.execute-api.us-west-2.amazonaws.com/dev',
  printerProxyUrl: 'https://printer-proxy-dev.tec.fit',
  videoFeedBaseUrl: 'wss://printer-proxy-dev.tec.fit',
  duetBaseUrl: 'https://dev.d3rqlkew2u9tta.amplifyapp.com',
  apiStage: 'dev'
}

export const AWS_CONFIG = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_aq5GzPBN7',
    userPoolWebClientId: '6qegauv5pekmgbse4rgvk8sjj7'
  }
}