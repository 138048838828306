export const PRINTER_STATUS = {
    C: { displayName: 'Configuring', tooltip: 'Configuration file is being processed' },
    I: { displayName: 'Ready', tooltip: 'Idle, no movement or code is being performed' },
    B: { displayName: 'Busy', tooltip: 'Busy, live movement is in progress or a macro file is being run' },
    P: { displayName: 'Printing', tooltip: 'Printing a file' },
    D: { displayName: 'Pausing', tooltip: 'Decelerating, pausing a running print' },
    S: { displayName: 'Paused', tooltip: 'Stopped, live print has been paused' },
    R: { displayName: 'Resuming', tooltip: 'Resuming a paused print' },
    H: { displayName: 'Stopped', tooltip: 'Halted, after emergency stop' },
    F: { displayName: 'Flashing', tooltip: 'Flashing new firmware' },
    T: { displayName: 'Changing', tooltip: 'Changing tool' },
    U: { displayName: 'Disconnected', tooltip: 'Could not connect to the printer' },
    DOWNLOADING_FILE: { displayName: 'Downloading File', tooltip: 'Downloading file to printer' }
}

export const PRINTER_SUCCESS_STATUS = ['I'];
export const PRINTER_PROCESSING_STATUS = ['C', 'D', 'R', 'F', 'T', 'P', 'DOWNLOADING_FILE'];
export const PRINTER_WARNING_STATUS = ['B', 'S'];
export const PRINTER_ERROR_STATUS = ['U', 'H'];

export const PRINTER_STATUS_READY = 'I';
export const PRINTER_STATUS_PRINTING = 'P';
export const PRINTER_STATUS_PAUSED = 'S';