import Amplify from 'aws-amplify';
import { CONFIG } from '../config/app-config';
import { APIS } from './api-utils';

const { baseApiUrl, printerProxyUrl, printerProxyApiUrl, userApiUrl } = CONFIG;

export async function awsAmplifyApiInit() {
    await Amplify.configure({
        API: {
            endpoints: [
                {
                    name: APIS.PRINTER_API,
                    endpoint: baseApiUrl
                },
                {
                    name: APIS.PRINTER_PROXY,
                    endpoint: printerProxyUrl
                },
                {
                    name: APIS.PRINTER_PROXY_API,
                    endpoint: printerProxyApiUrl
                },
                {
                    name: APIS.USER_API,
                    endpoint: userApiUrl
                }
            ]
        }
    });
}