import { Modal, Button, List, Typography, Tag, message, Row, Col, Input, Tabs, Alert } from "antd";
import { useEffect, useState } from "react";
import { getPendingUploads, uploadOuFileToPrinter } from "../../actions/PrinterActions";
import { formatBytes, getFileName } from "../../utils/common-utils";
import { deleteFile, getPrinterFileList } from "../../actions/PrinterProxyActions";
import { CheckCircleOutlined, SyncOutlined, DeleteOutlined, UploadOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;

let pendingUploadsCronId, printerFilesCronId;

export const FileManager = ({ isVisible, setIsVisible, printer, scans, ouId }) => {
    const [printerFileList, setPrinterFileList] = useState([]);
    const [pendingUploads, setPendingUploads] = useState([]);
    const [searchText, setSearchText] = useState();
    const [tabkey, setTabKey] = useState('cloud');

    useEffect(() => {
        loadPrinterFiles();
        loadPendingUploads();
        pendingUploadsCronId = setInterval(loadPrinterFiles, 30000);
        printerFilesCronId = setInterval(loadPendingUploads, 30000);
        return () => {
            clearInterval(pendingUploadsCronId);
            clearInterval(printerFilesCronId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPrinterFiles = () => {
        getPrinterFileList(printer.printerId, ouId).then(fileList => fileList ? setPrinterFileList(fileList) : console.log('No files'));
    }

    const loadPendingUploads = () => {
        getPendingUploads(printer.printerId).then(fileList => setPendingUploads(fileList));
    }

    const renderPrinterFileItems = () => {
        let fileList = searchText ? printerFileList.filter(({ name }) => name.toLowerCase().includes(searchText)) : printerFileList;
        return (
            <>
                <Alert
                    message="Files ready to print are displayed here."
                    type="info"
                    showIcon
                />
                {renderSearch()}
                <List>
                    {fileList.map(file =>
                        <List.Item key={file.name} actions={[<Button danger shape="round" size="small" icon={<DeleteOutlined />} onClick={() => onDeleteFile(file.name)}>Delete</Button>]}>
                            <List.Item.Meta
                                style={{ width: '140px' }}
                                avatar={<Tag icon={<CheckCircleOutlined />} color="success">Ready to Print</Tag>}
                                title={<>{file.name} <Text disabled>({formatBytes(file.size)})</Text></>}
                            />
                        </List.Item>
                    )}
                </List>
            </>
        );
    }

    const renderUploadingFileItems = () => {
        let fileList = searchText ? pendingUploads.filter(({ Key: fileKey }) => getFileName(fileKey).toLowerCase().includes(searchText)) : pendingUploads;
        return (
            <>
                <Alert
                    message="These are the files that are currently being transferred from cloud to the printer 🖨. Please note that the transferring file time may vary, depending on the file size, internet speed and the status of the printer."
                    type="info"
                    showIcon
                />
                {renderSearch()}
                <List>
                    {fileList.map(({ Key: fileKey, Size: fileSize }) =>
                        <List.Item key={fileKey}>
                            <List.Item.Meta
                                style={{ width: '140px' }}
                                avatar={<Tag icon={<SyncOutlined spin />} color="processing">Uploading</Tag>}
                                title={<>{getFileName(fileKey)} <Text disabled>({formatBytes(fileSize)})</Text></>}
                            />
                        </List.Item>
                    )}
                </List>
            </>
        );
    }

    const renderOUFileItems = () => {
        let fileList = searchText ? scans.filter(({ Key: fileKey }) => getFileName(fileKey).toLowerCase().includes(searchText)) : scans;
        return (
            <>
                <Alert
                    message="Files that has been uploaded to the cloud are displayed here. Please transfer the files from cloud to the printer, if you would like to print them."
                    type="info"
                    showIcon
                />
                {renderSearch()}
                <List>
                    {fileList.map(({ Key: fileKey, Size: fileSize }) =>
                        <List.Item key={fileKey} actions={[<Button onClick={() => onUploadFile(fileKey)} type="primary" shape="round" size="small" ghost icon={<UploadOutlined />}>Transfer to Printer</Button>]}>
                            <List.Item.Meta
                                style={{ width: '140px' }}
                                avatar={<Tag icon={<ClockCircleOutlined />} color="purple">Ready to Transfer</Tag>}
                                title={<>{getFileName(fileKey)} <Text disabled>({formatBytes(fileSize)})</Text></>}
                            />
                        </List.Item>
                    )}
                </List>
            </>
        );
    }

    const onUploadFile = (fileKey) => {
        Modal.confirm({
            title: `Do You Want to Upload Following File to ${printer.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: getFileName(fileKey),
            onOk: async () => {
                message.loading('Adding file to upload queue...', 0);
                let err = await uploadOuFileToPrinter(printer.printerId, fileKey, ouId);
                message.destroy();
                if (err) {
                    message.error(err);
                } else {
                    message.success('File is uploading');
                    getPendingUploads(printer.printerId).then(fileList => setPendingUploads(fileList));
                    setTabKey('uploading')
                }
            }
        });
    }

    const onDeleteFile = (fileName) => {
        Modal.confirm({
            title: `Do You Want to Delete Following File from ${printer.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: getFileName(fileName),
            onOk: async () => {
                message.loading('Deleting file...', 0);
                let err = await deleteFile(printer.printerId, ouId, `0:/gcodes/${fileName}`);
                message.destroy();
                if (err) {
                    message.error(err);
                } else {
                    message.success('File is deleted');
                    getPrinterFileList(printer.printerId, ouId).then(fileList => fileList ? setPrinterFileList(fileList) : console.log('No files'));
                }
            }
        });
    }

    const renderSearch = () => {
        return (
            <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Col span={8}>
                    <Search placeholder="Search Files" onChange={e => setSearchText(e.target.value.toLowerCase())} value={searchText} />
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            title={`Printer File Manager - ${printer.name}`}
            visible={isVisible}
            onOk={() => setIsVisible(false)}
            onCancel={() => setIsVisible(false)}
            width={'50%'}
        >
            <Tabs activeKey={tabkey} onChange={key => setTabKey(key)}>
                <TabPane tab={<><img src="/in-cloud.png" alt='icon' style={{ height: '25px', marginRight: '5px' }} />In Cloud</>} key="cloud">
                    {renderOUFileItems()}
                </TabPane>
                <TabPane tab={<><img src="/in-transit.png" alt='icon' style={{ height: '25px', marginRight: '5px' }} />In Transit</>} key="uploading">
                    {renderUploadingFileItems()}
                </TabPane>
                <TabPane tab={<><img src="/in-printer.png" alt='icon' style={{ height: '25px', marginRight: '5px' }} />In Printer</>} key="printer">
                    {renderPrinterFileItems()}
                </TabPane>
            </Tabs>
        </Modal >
    );
}