import { Menu } from 'antd';
import { UserOutlined, PrinterFilled } from '@ant-design/icons';

export const MENU = {
    PRINTERS: 'PRINTERS',
    USERS: 'USERS',
}

export const SideMenu = ({ setSelectedMenu }) => {
    return (
        <Menu
            className="side-menu"
            defaultSelectedKeys={[MENU.PRINTERS]}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
            inlineCollapsed={true}
            onSelect={({ item, key }) => setSelectedMenu(key)}
        >
            <Menu.Item key={MENU.PRINTERS} icon={<PrinterFilled />}>
                Printers
            </Menu.Item>
            <Menu.Item key={MENU.USERS} icon={<UserOutlined />}>
                Users
          </Menu.Item>
        </Menu>
    )
}